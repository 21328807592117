import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ListItem, NumberedList, Link } from '@entur/typography';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpandablePanel = makeShortcode("ExpandablePanel");
const Swagger = makeShortcode("Swagger");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "guides"
    }}>{`Guides`}</h1>
    <p>{`Seating manager handles seating offers and seat reservations. A seating offer contains data for the seats in the material used on a dated service journey.`}</p>
    <h2 {...{
      "id": "authentication"
    }}>{`Authentication`}</h2>
    <p>{`The Seating manager requires a Bearer Token in the Authorization header created by the Entur Authentication server to be passed for every request.`}</p>
    <h2 {...{
      "id": "table-of-contents"
    }}>{`Table of Contents`}</h2>
    <NumberedList mdxType="NumberedList">
    <ListItem mdxType="ListItem">
        <Link href="#create-reservations" mdxType="Link">
            Create reservations
        </Link>
        <NumberedList mdxType="NumberedList">
            <ListItem mdxType="ListItem">
                <Link href="#create-a-reservation-and-reserve-seats-by-seating-properties" mdxType="Link">Create a reservation and reserve seats by seating properties</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#create-a-reservation-with-a-specific-seat-and/or-compartment" mdxType="Link">Create a reservation with a specific seat and/or compartment</Link>
            </ListItem>
        </NumberedList>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#altering-a-seat-reservation" mdxType="Link">
            Altering a seat reservation
        </Link>
    </ListItem>
    </NumberedList>
    <h2 {...{
      "id": "create-reservations"
    }}>{`Create reservations`}</h2>
    <p>{`There are two different ways to create `}<a parentName="p" {...{
        "href": "/pages-entur-plass-concepts#seat-reservation"
      }}>{`reservations`}</a>{` depending on your use case.`}</p>
    <ol>
      <li parentName="ol">{`Create a reservation and reserve seats by `}<a parentName="li" {...{
          "href": "/pages-entur-plass-concepts#seating-properties"
        }}>{`SeatingProperties`}</a></li>
      <li parentName="ol">{`Create a reservation with a specific seat`}</li>
    </ol>
    <h3 {...{
      "id": "create-a-reservation-and-reserve-seats-by-seating-properties"
    }}>{`Create a reservation and reserve seats by seating properties`}</h3>
    <p><a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.dev.entur.io/seating-manager/v3/api-docs#/reservation-controller/allocateReservationBySeatingProperties"
      }}>{`POST /v1-beta/reservations/allocate`}</a></p>
    <h4 {...{
      "id": "create-a-draft-reservation-and-reserve-seat-and-compartment-by-seating-properties"
    }}>{`Create a DRAFT reservation and reserve seat and compartment by seating properties`}</h4>
    <p>{`The following example shows how to reserve a single seating standard seat, and in the same request reserve a sleeper compartment on the same journey. The reservation is from `}<a parentName="p" {...{
        "href": "https://stoppested.entur.org/stop_place/NSR:StopPlace:337"
      }}>{`Oslo`}</a>{` to `}<a parentName="p" {...{
        "href": "https://stoppested.entur.org/stop_place/NSR:StopPlace:596"
      }}>{`Stavanger`}</a>{` on the 12th of December 2023. Two reservation lines connected to the same reservation will be generated based on the following seating properties requests. The first seating properties request indicates that the seating properties to reserve is STANDARD fareClass and seating accommodation facility SEATING (read more about `}<a parentName="p" {...{
        "href": "/pages-entur-plass-concepts#seating-properties"
      }}>{`SeatingProperties`}</a>{`). The second seating properties request indicates that the seating properties to reserve is PREMIUM_CLASS fareClass and seating accommodation facility SLEEPER. For the compartment reservation line to be valid one must supply the mustReserveWholeCompartment flag as true. In the following example, the reservation is created as a DRAFT reservation and marked as connected to a orderId(A1B2C3D4) in an external sales system.`}</p>
    <ExpandablePanel title="Example request" mdxType="ExpandablePanel">
      <pre>{`{
{
  "originNsrCode": "NSR:StopPlace:596",
  "destinationNsrCode": "NSR:StopPlace:337",
  "datedServiceJourneyId": "GOA:DatedServiceJourney:725_OSL-STV_23-12-12",
  "status": "DRAFT",
  "orderId": "A1B2C3D4",
  "seatingPropertiesRequests": [
    {
      "seatingPropertiesToReserve": {
        "fareClass": "STANDARD",
        "accommodationFacility": "SEATING",
        },
      "mustReserveWholeCompartment": false,
      "orderLineId": "d4ed2005-6448-4c4e-91f5-f525ccbe877c",
    }
    {
      "seatingPropertiesToReserve": {
        "fareClass": "PREMIUM_CLASS",
        "accommodationFacility": "SLEEPER",
        },
      "mustReserveWholeCompartment": true,
      "orderLineId": "d4ed2005-6448-4c4e-91f5-f525ccbe877c",
    }
  ]
}}]
}`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
    <pre>{`{
  "version": 1,
  "originStopPlace": {
    "nsrCode": "NSR:StopPlace:337",
    "sequenceNr": 0
  },
  "destinationStopPlace": {
    "nsrCode": "NSR:StopPlace:596",
    "sequenceNr": 0
  },
  "datedServiceJourneyId": "GOA:DatedServiceJourney:725_OSL-STV_23-12-12",
  "status": "DRAFT",
  "reference": "c129cc9c-76e4-4f28-a4e2-5af96550627a",
  "organisationId": 20,
  "createdAt": "2011-12-03T10:15:30+01:00",
  "versionedAt": "2011-12-03T10:15:30+01:00",
  "updatedAt": "2011-12-03T10:15:30+01:00",
  "reservationLines": [
    {
      "orderLineId": "d4ed2005-6448-4c4e-91f5-f525ccbe877c",
      "reference": "d2de5c04-95f9-400f-9511-1c28ab7cfd91",
      "elementCarriageNumber": "2",
      "seatNumber": "1",
      "type": "SINGLE_SEAT",
      "reservedProperties": [
        ["FareClass_STANDARD_CLASS", "AccommodationFacility_SEATING"]
      ],
      "requestedProperties": [
        ["FareClass_STANDARD_CLASS", "AccommodationFacility_SEATING"]
      ]
    },
    {
      "orderLineId": "d4ed2005-6448-4c4e-91f5-f525ccbe877c",
      "reference": "89333b5a-b34a-4c0c-8b84-b598bf894d55",
      "compartmentName": "GOA-WLAB2-PREMIUM-SLEEPER-9",
      "elementCarriageNumber": "11",
      "numberOfTravellers": "2",
      "type": "COMPARTMENT",
      "reservedProperties": [
        ["FareClass_PREMIUM_CLASS", "AccommodationFacility_SLEEPER"]
      ],
      "requestedProperties": [
        "[FareClass_PREMIUM_CLASS, AccommodationFacility_SLEEPER]"
      ]
    }
  ],
  "orderId": "A1B2C3D4",
  "createdBy": 20,
  "updatedBy": 20
}`}</pre>
    </ExpandablePanel>
    <h3 {...{
      "id": "create-a-reservation-with-a-specific-seat-andor-compartment"
    }}>{`Create a reservation with a specific seat and/or compartment`}</h3>
    <p>{`The case here is that you have the seat number and element carriage number of a specific seat and/or compartment belonging to a specific dated service journey and want to create a reservation for that seat and/or compartment. A common use case is that the traveler is provided with a seat map and selects a seat and then the client generate a reservation request based on that seat. The following example shows how to create a single seating standard seat reservation and reserve a specific seat with seat number 21 and element carriage number 7 and a couchette compartment on the dated service journey `}<inlineCode parentName="p">{`VYG:DatedServiceJourney:606_BRG-OSL_23-12-10`}</inlineCode>{` on the 12th of Desember in 2023. The travelers wants to travel from `}<a parentName="p" {...{
        "href": "https://stoppested.entur.org/stop_place/NSR:StopPlace:548"
      }}>{`Bergen rail station`}</a>{` to `}<a parentName="p" {...{
        "href": "https://stoppested.entur.org/stop_place/NSR:StopPlace:337"
      }}>{`Oslo S`}</a>{`. The reservation reference and reservation line reference will both be generated by Seating Manager.`}</p>
    <p><a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.dev.entur.io/seating-manager/v3/api-docs#/reservation-controller/createReservation"
      }}>{`POST /v1-beta/reservations/`}</a></p>
    <ExpandablePanel title="Example request" mdxType="ExpandablePanel">
      <pre>{`{
    "datedServiceJourneyId": "VYG:DatedServiceJourney:606_BRG-OSL_23-12-10",
    "destinationNsrCode": "NSR:StopPlace:337",
    "originNsrCode": "NSR:StopPlace:548",
    "reservationLines": [
    {
        "elementCarriageNumber": "7",
        "seatNumber": "21",
        "type": "SINGLE_SEAT"
    },
    {
        "compartmentName": "VYG-BFWL1-COUCHETTES-COMPARTMENT-4",
        "elementCarriageNumber": "5",
        "numberOfTravellers":" 6",
        "type": "COMPARTMENT"
    }
    ],
    "status": "DRAFT"
}`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
      <pre>{`{
    "createdAt": "2023-10-25T12:43:17.90995+02:00",
    "createdBy": 1,
    "datedServiceJourneyId": "NSB:DatedServiceJourney:808_SKN-EVL_23-11-01",
    "destinationStopPlace": {
        "nsrCode": "NSR:StopPlace:337",
        "sequenceNr": "-1"
    },
    "orderId": null,
    "originStopPlace": {
        "nsrCode": "NSR:StopPlace:653",
        "sequenceNr": "-1"
    },
    "rebookingStatus": null,
    "reference": "0a64d15b-d793-4100-92ba-e6fe3e35b00c",
    "reservationLines": [
        {
            "elementCarriageNumber": 7,
            "orderLineId": null,
            "reference": "19dab27b-0100-4126-a9c7-acf48bd62eea",
            "requestedProperties": ["FareClass_STANDARD_CLASS", "AccommodationFacility_SEATING"],
            "reservedProperties": ["FareClass_STANDARD_CLASS", "AccommodationFacility_SEATING"],
            "seatNumber": "21",
            "type": "SINGLE_SEAT"
        },
        {
            "compartmentName": "VYG-BFWL1-COUCHETTES-COMPARTMENT-4",
            "elementCarriageNumber": 5,
            "numberOfTravellers": 6,
            "orderLineId": null,
            "reference": "f764b687-eed1-418e-9a06-9605e46fcec7",
            "requestedProperties": ["FareClass_STANDARD_CLASS","AccommodationFacility_COUCHETTE"],
            "reservedProperties": ["FareClass_STANDARD_CLASS", "AccommodationFacility_COUCHETTE"],
            "seatsInCompartment": ["6"],
            "type": "COMPARTMENT"
        }
    ],
    "status": "DRAFT"
}`}</pre>
    </ExpandablePanel>
    <h2 {...{
      "id": "altering-a-seat-reservation"
    }}>{`Altering a seat reservation`}</h2>
    <p>{`Reservations in seating manager can be changed by using the API.`}</p>
    <p><img alt="Creating and altering a reservation" src={require("./create-confirm-reservation.png")} /></p>
    <p>{`When a reservation is first created it will have status=DRAFT. Seats are reserved by adding reservation lines, e.g. we can make a SingleSeatReservationLine that occupies SeatNumber 1 in carriage number 1. Changes to reservation lines in a DRAFT reservation is effectuated immediately and the reservation will remain in DRAFT state. For instance, we can send a PUT request that reserves SeatNumber 2. SeatNumber 1 will then be freed up and available for other customers.`}</p>
    <p><img alt="Altering a confirmed reservation" src={require("./update-confirmed-reservation.png")} /></p>
    <p>{`When a reservation is in CONFIRMED state, all changes to the reservation will result in a new version of the reservation.
The old version will still be CONFIRMED, and a new DRAFT version will be returned to the client (with the version-field incremented by 1).
Seats that was reserved in the CONFIRMED state, but are no longer present in the DRAFT reservation is not yet released, thus the CONFIRMED version can still occupy seats. They are not released until the DRAFT version is CONFIRMED. Confirmation of a DRAFT reservation is thus analogous to a commit in a version control system like git. Upon confirmation, the previous CONFIRMED version gets status DEPRECATED and is only relevant for historical purposes.`}</p>
    <p>{`When a reservation is in CANCELLED state, it cannot be changed anymore.`}</p>
    <h1 {...{
      "id": "seating-manager-api-reference"
    }}>{`Seating manager API Reference`}</h1>
    <p><a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.staging.entur.io/seating-manager/v3/api-docs"
      }}>{`See the API reference on Swagger Petstore`}</a></p>
    <Swagger url="https://api.staging.entur.io/seating-manager/v3/api-docs" fallback="https://petstore.swagger.io/?url=https://api.staging.entur.io/seating-manager/v3/api-docs" mdxType="Swagger" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      